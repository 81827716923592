<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="">
              <h3>Historial <small>total {{ totalProductCategories }}</small></h3>
            </div>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end  mb-1 mb-md-0">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar por codigo"
              />
              <b-button
                variant="primary"
                @click="downloadHistories"
              >
                Excel
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refProductCategoriesListTable"
        class="position-relative"
        :items="fetchProductHistories"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No se encontraron registros coincidentes"
      >

        <!-- Column: product Code -->
        <template #cell(product_code)="data">
          <b-link
            class="font-weight-bold d-block text-nowrap"
            :to="{ name: 'product-income-expenses', params: {code:data.item.product_code}}"
          >
            {{ data.item.product_code }}
          </b-link>
        </template>

        <!-- Column: product name -->
        <template #cell(product_name)="data">
          <b-link
            class="font-weight-bold d-block text-nowrap"
            :to="{ name: 'product-income-expenses', params: {code:data.item.product_code}}"
          >
            {{ data.item.product_name }}
          </b-link>
        </template>

        <!-- Column: Client -->
        <template #cell(date_max)="data">
          <span class="text-nowrap">
            {{ data.item.last_quantity }} unidades - {{ data.item.date_max }}
          </span>
        </template>

        <!-- Column: Actions -->

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-end justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalProductCategories"
              per-page="10"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BPagination,
  BFormInput,
  BLink,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import productHistoryHandler from '@/views/dramox/product/history/productHistoryHandler'
import productHistoryStoreModule from '@/views/dramox/product/history/productHistoryStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BButton,
    BFormInput,
    BLink,
  },
  directives: {
    Ripple,
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-products-histories'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, productHistoryStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({ title, icon, variant }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const {
      // fetchUsers,
      tableColumns,
      totalProductCategories,

      perPage,
      currentPage,

      perPageOptions,
      refProductCategoriesListTable,
      refetchData,
      fetchProductHistories,
      searchQuery,

    } = productHistoryHandler()

    const downloadHistories = () => {
      store.dispatch('app-products-histories/downloadProductHistories', { searchText: searchQuery.value })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'historial.xlsx')
          document.body.appendChild(link)
          link.click()
          // showNotification({ title: 'Eliminado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        }).catch(e => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    return {
      tableColumns,

      perPage,
      currentPage,
      totalProductCategories,

      perPageOptions,
      refProductCategoriesListTable,
      refetchData,

      fetchProductHistories,
      searchQuery,

      downloadHistories,
    }
  },
}
</script>
