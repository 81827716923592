import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProductHistories(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/product/histories', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchHistoryStatistics(ctx, { params }) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/product/histories/statistic', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchHistoryExpenses(ctx, { params }) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/product/histories/expenses', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchHistoryIncomes(ctx, { params }) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/product/histories/incomes', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadProductHistories(ctx, { searchText }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/product/histories/download', { params: { searchText }, responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadProductHistoriesIncomes(ctx, { params }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/product/histories/download/incomes', { params, responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadProductHistoriesExpenses(ctx, { params }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/product/histories/download/expenses', { params, responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
